import { Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Container, IconButton, InputAdornment, Stack, TextField, Typography, styled } from '@mui/material'
import MuiPhoneNumber from 'material-ui-phone-number'
import { useState } from 'react'
import dentalbookLogo from '../assets/icon.svg'
import { IKioskRegisterProps } from '../types/RegisterTypes'

export const KioskRegisterPage = ({ registerState, onChangeRegisterState, onRegisterPress, theme, logo }: IKioskRegisterProps) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleShowPassword = () => {
    setShowPassword(show => !show)
  }

  return (
    <Container sx={{ padding: 0, height: 'inherit' }}>
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Box
              sx={{
                display: 'flex',
                alignSelf: 'center',
                marginBottom: 2,
                [theme.breakpoints.down('sm')]: {
                  height: 120
                }
              }}
            >
              <img
                src={logo}
                style={{
                  width: 200,
                  objectFit: 'contain'
                }}
              />
            </Box>
            <form>
              <Stack spacing={3} marginBottom={3}>
                <TextField
                  fullWidth
                  autoComplete="email"
                  type="email"
                  label="Email"
                  value={registerState.patientEmail}
                  onChange={e =>
                    onChangeRegisterState({ ...registerState, patientEmail: e.target.value, errors: { ...registerState.errors, email: '' } })
                  }
                  error={registerState.errors.email !== ''}
                  helperText={registerState.errors.email}
                />
                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  value={registerState.password}
                  onChange={e =>
                    onChangeRegisterState({ ...registerState, password: e.target.value, errors: { ...registerState.errors, password: '' } })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  error={registerState.errors.password !== ''}
                  helperText={
                    registerState.errors.password === ''
                      ? 'A senha deve ter pelo menos 8 caracteres e conter pelo menos um número, uma letra maiúscula e um caractere especial. Ex: Senha08!'
                      : registerState.errors.password
                  }
                />
                <Stack flexDirection={'column'} spacing={3}>
                  <MuiPhoneNumber
                    defaultCountry={'pt'}
                    value={registerState.patientPhone}
                    onChange={value =>
                      onChangeRegisterState({ ...registerState, patientPhone: value, errors: { ...registerState.errors, phoneNumber: '' } })
                    }
                    fullWidth
                    variant="outlined"
                    label="Telemóvel"
                    error={registerState.errors.phoneNumber !== ''}
                    helperText={registerState.errors.phoneNumber}
                  />
                  <TextField
                    fullWidth
                    autoComplete="patient_nif"
                    type="patient_nif"
                    label="NIF"
                    value={registerState.patientNif}
                    onChange={e =>
                      onChangeRegisterState({ ...registerState, patientNif: e.target.value, errors: { ...registerState.errors, patientNif: '' } })
                    }
                    error={registerState.errors.patientNif !== ''}
                    helperText={registerState.errors.patientNif}
                  />
                </Stack>
                {registerState.errors.name ===
                  'Os seus dados não estão ainda associados a esta clínica, por favor complete-os para o primeiro registo.' && (
                  <>
                    <TextField
                      autoFocus
                      fullWidth
                      autoComplete="name"
                      type="name"
                      label="Nome"
                      value={registerState.name}
                      onChange={e => onChangeRegisterState({ ...registerState, name: e.target.value })}
                    />
                    <TextField
                      fullWidth
                      autoComplete="city"
                      type="city"
                      label="Localidade"
                      value={registerState.city}
                      onChange={e => onChangeRegisterState({ ...registerState, city: e.target.value })}
                    />
                  </>
                )}
              </Stack>
            </form>

            <LoadingButton onClick={onRegisterPress} fullWidth size="large" type="submit" variant="contained" loading={false}>
              Registar
            </LoadingButton>
          </ContentStyle>
        </Container>
      </RootStyle>
      <Box sx={{ display: 'flex', height: '5vw', overflow: 'hidden', justifyContent: 'center', alignItems: 'flex-end' }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Desenvolvido por{' '}
          <a target="_blank" rel="noreferrer" href="https://www.imaginasoft.pt">
            Imaginasoft
          </a>
        </Typography>
      </Box>
      <Box sx={{ position: 'absolute', filter: 'blur(12px)', zIndex: -1, bottom: 0, left: '-5%', transform: 'scaleX(-1)' }}>
        <img src={dentalbookLogo} style={{ width: 400, objectFit: 'contain' }} />
      </Box>
    </Container>
  )
}

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  backgroundColor: 'transparent',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

const ContentStyle = styled('div')(({ theme }) => ({
  width: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2, 0)
}))
