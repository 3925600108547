import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab'
import { IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import MuiPhoneNumber from 'material-ui-phone-number'
import { useState } from 'react'
import { IRegisterProps } from '../types/RegisterTypes'

export const Register = ({
  registerState,
  onChangeRegisterState,
  onRegisterPress,
  onRegisterNewPatientPress,
  onInsertCodeState,
  onInsertCodePress,
  onResendCodePress
}: IRegisterProps) => {
  const [showPassword, setShowPassword] = useState(false)
  // const enterPress = useKeyPress('Enter')

  // useEffect(() => {
  //   if (enterPress) {
  //     onRegisterPress()
  //   }
  // }, [enterPress, onRegisterPress])

  const handleShowPassword = () => {
    setShowPassword(show => !show)
  }

  if (onInsertCodeState) {
    return (
      <>
        <form>
          <Stack spacing={3} marginBottom={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email"
              value={registerState.patientEmail}
              onChange={e =>
                onChangeRegisterState({ ...registerState, patientEmail: e.target.value, errors: { ...registerState.errors, email: '' } })
              }
              error={registerState.errors.email !== ''}
              helperText={registerState.errors.email}
            />
            <TextField
              fullWidth
              autoComplete="code"
              type="code"
              label="Código SMS"
              value={registerState.phoneCode}
              onChange={e =>
                onChangeRegisterState({ ...registerState, phoneCode: e.target.value, errors: { ...registerState.errors, phoneCode: '' } })
              }
              error={registerState.errors.phoneCode !== ''}
              helperText={registerState.errors.phoneCode}
            />
          </Stack>
        </form>
        <Stack spacing={3} marginBottom={3}>
          <LoadingButton onClick={onInsertCodePress} fullWidth size="large" type="submit" variant="contained" loading={false}>
            Submeter Código SMS
          </LoadingButton>
          <Typography onClick={onResendCodePress} variant="subtitle2" sx={{ textAlign: 'center', cursor: 'pointer' }}>
            Reenviar Código SMS
          </Typography>
          {/* <LoadingButton onClick={onResendCodePress} fullWidth size="large" type="submit" variant="contained" loading={false}>
            Reenviar Código SMS
          </LoadingButton> */}
        </Stack>
      </>
    )
  }

  return (
    <>
      <form>
        <Stack spacing={3} marginBottom={3}>
          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            label="Insira o seu Email"
            value={registerState.patientEmail}
            onChange={e => onChangeRegisterState({ ...registerState, patientEmail: e.target.value, errors: { ...registerState.errors, email: '' } })}
            error={registerState.errors.email !== ''}
            helperText={registerState.errors.email}
          />
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Defina a sua Password"
            value={registerState.password}
            onChange={e => onChangeRegisterState({ ...registerState, password: e.target.value, errors: { ...registerState.errors, password: '' } })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={registerState.errors.password !== ''}
            helperText={
              registerState.errors.password === ''
                ? 'A senha deve ter pelo menos 8 caracteres e conter pelo menos um número, uma letra maiúscula e um caractere especial. Ex: Senha24!'
                : registerState.errors.password
            }
          />
          <Stack flexDirection={'column'} spacing={3}>
            <MuiPhoneNumber
              defaultCountry={'pt'}
              value={registerState.patientPhone}
              onChange={value =>
                onChangeRegisterState({ ...registerState, patientPhone: value, errors: { ...registerState.errors, phoneNumber: '' } })
              }
              fullWidth
              variant="outlined"
              label="Telemóvel"
              error={registerState.errors.phoneNumber !== ''}
              helperText={registerState.errors.phoneNumber}
            />
            <TextField
              fullWidth
              autoComplete="patient_nif"
              type="patient_nif"
              label="NIF"
              value={registerState.patientNif}
              onChange={e =>
                onChangeRegisterState({ ...registerState, patientNif: e.target.value, errors: { ...registerState.errors, patientNif: '' } })
              }
              error={registerState.errors.patientNif !== ''}
              helperText={registerState.errors.patientNif}
            />
          </Stack>
          {registerState.errors.name ===
            'Os seus dados não estão ainda associados a esta clínica, por favor complete-os para o primeiro registo.' && (
            <>
              <TextField
                autoFocus
                fullWidth
                autoComplete="name"
                type="text"
                label="O seu Nome"
                value={registerState.name}
                onChange={e => onChangeRegisterState({ ...registerState, name: e.target.value })}
                error={Boolean(registerState.errors.name)}
                helperText={registerState.errors.name}
              />

              <TextField
                fullWidth
                autoComplete="city"
                type="city"
                label="Localidade"
                value={registerState.city}
                onChange={e => onChangeRegisterState({ ...registerState, city: e.target.value })}
              />
            </>
          )}
        </Stack>
      </form>
      {registerState.errors.name === 'Os seus dados não estão ainda associados a esta clínica, por favor complete-os para o primeiro registo.' ? (
        <LoadingButton onClick={onRegisterNewPatientPress} fullWidth size="large" type="submit" variant="contained" loading={false}>
          Registar Novo Paciente
        </LoadingButton>
      ) : (
        <LoadingButton onClick={onRegisterPress} fullWidth size="large" type="submit" variant="contained" loading={false}>
          Registar
        </LoadingButton>
      )}
    </>
  )
}
