import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab'
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useKeyPress } from '../hooks/useKeyPress'
import { ILoginProps } from '../types/LoginTypes'

export const Login = ({ loginState, loginLoading, onChangeLoginState, onLoginPress, onSendSMSPress }: ILoginProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const enterPress = useKeyPress('Enter')

  const handleShowPassword = () => {
    setShowPassword(show => !show)
  }

  useEffect(() => {
    if (!loginLoading && enterPress) {
      onLoginPress()
    }
  }, [enterPress, loginLoading, onLoginPress])

  return (
    <>
      <form>
        <Stack spacing={3} sx={{ mt: 5 }}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email"
            value={loginState.email}
            onChange={e => onChangeLoginState({ ...loginState, email: e.target.value, errors: { ...loginState.errors, email: '', password: '' } })}
            error={loginState.errors.email !== ''}
            helperText={loginState.errors.email}
          />
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            value={loginState.password}
            onChange={e => onChangeLoginState({ ...loginState, password: e.target.value, errors: { ...loginState.errors, email: '', password: '' } })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={loginState.errors.password !== ''}
            helperText={loginState.errors.password}
          />
        </Stack>
      </form>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      {loginState.errors.email ===
        'Verifique a sua conta primeiro, com o sms que recebeu após o registo. Pode voltar a enviar carregando no botão "Enviar SMS"' && (
        <Stack spacing={1} sx={{ mt: 2, mb: 2 }}>
          <LoadingButton onClick={onSendSMSPress} fullWidth size="large" type="submit" variant="contained" loading={loginLoading}>
            Enviar SMS
          </LoadingButton>
        </Stack>
      )}

      <LoadingButton onClick={onLoginPress} fullWidth size="large" type="submit" variant="contained" loading={loginLoading}>
        Entrar
      </LoadingButton>
    </>
  )
}
