import { Box, Container, Tab, Tabs, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
// import clinicLogo from '../assets/clinic_logo.png'
// import { Federated } from '../components/Federated'
import { Login } from '../components/Login'
import { NewPassword } from '../components/NewPassword'
import { Register } from '../components/Register'
import { IAuthenticationPageProps } from '../types/AuthenticationPageTypes'

export const AuthenticationPage = ({
  logo,
  tab,
  onTabPress,
  recover,
  onRecoverPress,
  loginState,
  loginLoading,
  onChangeLoginState,
  onLoginPress,
  onSendSMSPress,
  registerState,
  onChangeRegisterState,
  onRegisterPress,
  onRegisterNewPatientPress,
  onInsertCodeState,
  onInsertCodePress,
  handleInsertCodeState,
  onResendCodePress,
  newPasswordState,
  onChangeNewPasswordState,
  onNewResetPasswordPress,
  onNewPasswordPress,
  theme,
  version
}: IAuthenticationPageProps) => {
  return (
    <Container sx={{ padding: 0, height: 'inherit' }}>
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Box
              sx={{
                display: 'flex',
                alignSelf: 'center',
                marginBottom: 2,
                [theme.breakpoints.down('sm')]: {
                  height: 120
                }
              }}
            >
              <img
                src={logo}
                style={{
                  width: 200,
                  objectFit: 'contain'
                }}
              />
            </Box>
            {recover ? (
              <>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <NewPassword
                    newPasswordState={newPasswordState}
                    onChangeNewPasswordState={onChangeNewPasswordState}
                    onNewResetPasswordPress={onNewResetPasswordPress}
                    onNewPasswordPress={onNewPasswordPress}
                  />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                  <Typography onClick={onRecoverPress} variant="subtitle2" sx={{ textAlign: 'center', marginTop: 5, cursor: 'pointer' }}>
                    Voltar ao Login.
                  </Typography>
                  {newPasswordState.insertCode === false && (
                    <Typography
                      onClick={() => onChangeNewPasswordState({ ...newPasswordState, insertCode: true })}
                      variant="subtitle2"
                      sx={{ textAlign: 'center', marginTop: 5, marginLeft: 0.5, cursor: 'pointer' }}
                    >
                      | Já tenho o código.
                    </Typography>
                  )}
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={tab} onChange={onTabPress} variant="fullWidth">
                    <Tab label="Entrar" />
                    <Tab label="Registar" />
                  </Tabs>
                </Box>

                <TabPanel value={tab} index={0}>
                  {/* <Federated />
                  <Divider sx={{ my: 3 }}>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      OU
                    </Typography>
                  </Divider> */}
                  <Login
                    loginState={loginState}
                    loginLoading={loginLoading}
                    onChangeLoginState={onChangeLoginState}
                    onLoginPress={onLoginPress}
                    onSendSMSPress={onSendSMSPress}
                  />
                  <Typography onClick={e => onTabPress(e, 1)} variant="subtitle2" sx={{ textAlign: 'center', marginTop: 5, cursor: 'pointer' }}>
                    Se ainda nao se registou, clique aqui.
                  </Typography>
                  <Typography onClick={onRecoverPress} variant="subtitle2" sx={{ textAlign: 'center', marginTop: 5, cursor: 'pointer' }}>
                    Esqueceu-se da Password?
                  </Typography>
                </TabPanel>

                <TabPanel value={tab} index={1}>
                  <Register
                    registerState={registerState}
                    onChangeRegisterState={onChangeRegisterState}
                    onRegisterPress={onRegisterPress}
                    onRegisterNewPatientPress={onRegisterNewPatientPress}
                    onInsertCodeState={onInsertCodeState}
                    onInsertCodePress={onInsertCodePress}
                    onResendCodePress={onResendCodePress}
                  />
                  {onInsertCodeState ? (
                    <Typography onClick={handleInsertCodeState} variant="subtitle2" sx={{ textAlign: 'center', marginTop: 5, cursor: 'pointer' }}>
                      Voltar ao ecrã registar.
                    </Typography>
                  ) : (
                    <Typography onClick={handleInsertCodeState} variant="subtitle2" sx={{ textAlign: 'center', marginTop: 5, cursor: 'pointer' }}>
                      Já tem código SMS de verificação?
                    </Typography>
                  )}
                </TabPanel>
              </>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
      <Box sx={{ display: 'flex', height: '5vw', overflow: 'hidden', justifyContent: 'center', alignItems: 'flex-end' }}>
        <Typography variant="body2" sx={{ color: 'text.secondary', marginRight: 0.5 }}>
          v.{version} -
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Desenvolvido por{' '}
          <a target="_blank" rel="noreferrer" href="https://www.imaginasoft.pt">
            Imaginasoft
          </a>
        </Typography>
      </Box>
      {/* <Box sx={{ position: 'absolute', filter: 'blur(12px)', zIndex: -1, bottom: 0, left: '-5%', transform: 'scaleX(-1)' }}>
        <img src={dentalbookLogo} style={{ width: 400, objectFit: 'contain' }} />
      </Box> */}
    </Container>
  )
}

interface ITabPanelProps {
  children: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  backgroundColor: 'transparent',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

const ContentStyle = styled('div')(({ theme }) => ({
  width: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2, 0)
}))
