import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab'
import { IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useKeyPress } from '../hooks/useKeyPress'
import { INewPasswordProps } from '../types/NewPasswordTypes'

export const NewPassword = ({ newPasswordState, onChangeNewPasswordState, onNewResetPasswordPress, onNewPasswordPress }: INewPasswordProps) => {
  const enterPress = useKeyPress('Enter')
  const [showPassword, setShowPassword] = useState(false)

  const handleShowPassword = () => {
    setShowPassword(show => !show)
  }

  useEffect(() => {
    if (enterPress) {
      onNewPasswordPress()
    }
  }, [enterPress, onNewPasswordPress])

  return (
    <>
      <form>
        <Stack spacing={3}>
          <Typography variant="h5" component="h1" sx={{ textAlign: 'center' }}>
            Recuperar Password
          </Typography>
          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            label="Email"
            value={newPasswordState.email}
            onChange={e =>
              onChangeNewPasswordState({ ...newPasswordState, email: e.target.value, errors: { ...newPasswordState.errors, email: '' } })
            }
            error={newPasswordState.errors.email !== ''}
            helperText={newPasswordState.errors.email}
          />
          {newPasswordState.insertCode === true ? (
            <>
              <TextField
                fullWidth
                autoComplete="code"
                type="code"
                label="Código"
                value={newPasswordState.code}
                onChange={e =>
                  onChangeNewPasswordState({ ...newPasswordState, code: e.target.value, errors: { ...newPasswordState.errors, code: '' } })
                }
                error={newPasswordState.errors.code !== ''}
                helperText={newPasswordState.errors.code}
              />
              <TextField
                fullWidth
                autoComplete="new-password"
                type={showPassword ? 'text' : 'password'}
                label="Nova Password"
                value={newPasswordState.newPassword}
                onChange={e =>
                  onChangeNewPasswordState({
                    ...newPasswordState,
                    newPassword: e.target.value,
                    errors: { ...newPasswordState.errors, newPassword: '' }
                  })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={newPasswordState.errors.newPassword !== ''}
                helperText={
                  newPasswordState.errors.newPassword === ''
                    ? 'A senha deve ter pelo menos 8 caracteres e conter pelo menos um número, uma letra maiúscula e um caractere especial. Ex: Senha08!'
                    : newPasswordState.errors.newPassword
                }
              />
            </>
          ) : (
            <></>
          )}
        </Stack>
      </form>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
      {newPasswordState.insertCode === false ? (
        <LoadingButton onClick={onNewResetPasswordPress} fullWidth size="large" type="submit" variant="contained" loading={false}>
          Enviar email
        </LoadingButton>
      ) : (
        <LoadingButton onClick={onNewPasswordPress} fullWidth size="large" type="submit" variant="contained" loading={false}>
          Definir Password
        </LoadingButton>
      )}
    </>
  )
}

// err AuthError: Username cannot be empty
// err CodeMismatchException: Invalid verification code provided, please try again.
